import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import '../css/blog.css'

class Blog extends React.Component {
  render() {
    const siteData = this.props.data
    const blogData = this.props.data.allContentfulBlogPost.edges
    // let cards = []
    // blogData.forEach(edge => {
    //   let post = edge.node
    //   cards.push({
    //     title: post.title,
    //     body: post.subtitle,
    //     url: `/blog/${post.topicCluster}/${post.slug}`,
    //     image: 'https:' + post.image.fluid.src,
    //   })
    // })
    return (
      <div>
        <Navbar
          color="white"
          spaced={true}
          data={siteData.allContentfulNavbar}
        />
        <SEO title="Blog" />
        <div className="container">
          <div className="inner">
            <div className="post-feed">
              <article className="post-card post">
                <a
                  className="post-card-image-link"
                  href={`/blog/${blogData[0].node.topicCluster}/${
                    blogData[0].node.slug
                  }`}
                >
                  <div
                    className="post-card-image"
                    style={{
                      backgroundImage:
                        'url(' + blogData[0].node.image.fluid.src + ')',
                    }}
                  />
                </a>
                <div className="post-card-content">
                  <a
                    className="post-card-content-link"
                    href={`/blog/${blogData[0].node.topicCluster}/${
                      blogData[0].node.slug
                    }`}
                  >
                    <header className="post-card-header">
                      <span className="post-card-tags">
                        {blogData[0].node.topicCluster}
                      </span>
                      <h2 className="post-card-title">
                        {blogData[0].node.title}
                      </h2>
                    </header>
                    <section className="post-card-excerpt">
                      <p>{blogData[0].node.subtitle}</p>
                    </section>
                  </a>
                  <footer className="post-card-meta">
                    <img
                      className="author-profile-image"
                      src={blogData[0].node.authorImage.fluid.src}
                      alt={blogData[0].node.author}
                    />
                    <span className="post-card-author">
                      <a href="/about-us">{blogData[0].node.author}</a>
                    </span>
                  </footer>
                </div>
              </article>
            </div>
          </div>
          <div className="inner">
            <div className="post-feed">
              {blogData.slice(1).map(edge => {
                const post = edge.node
                return (
                  <article className="post-card post">
                    <a
                      className="post-card-image-link"
                      href={`/blog/${post.topicCluster}/${post.slug}`}
                    >
                      <div
                        className="post-card-image"
                        style={{
                          backgroundImage: 'url(' + post.image.fluid.src + ')',
                        }}
                      />
                    </a>
                    <div className="post-card-content">
                      <a
                        className="post-card-content-link"
                        href={`/blog/${post.topicCluster}/${post.slug}`}
                      >
                        <header className="post-card-header">
                          <span className="post-card-tags">
                            {post.topicCluster}
                          </span>
                          <h2 className="post-card-title">{post.title}</h2>
                        </header>
                        <section className="post-card-excerpt">
                          <p>{post.subtitle}</p>
                        </section>
                      </a>
                      <footer className="post-card-meta">
                        <img
                          className="author-profile-image"
                          src={post.authorImage.fluid.src}
                          alt={post.author}
                        />
                        <span className="post-card-author">
                          <a href="/about-us">{post.author}</a>
                        </span>
                      </footer>
                    </div>
                  </article>
                )
              })}
            </div>
          </div>
        </div>
        <Footer
          color="light"
          size="medium"
          logo={siteData.allContentfulNavbar.edges[0].node.brandLogo}
          description={siteData.allContentfulFooter.edges[0].node.description}
          copyright={
            '© ' +
            siteData.allContentfulFooter.edges[0].node.companyName +
            ` ${new Date().getFullYear()}`
          }
          socialPages={siteData.allContentfulFooter.edges[0].node.socialPages}
          socialImages={siteData.allContentfulFooter.edges[0].node.socialImages}
          driftAppId={siteData.allContentfulFooter.edges[0].node.driftAppId}
        />
      </div>
    )
  }
}

export default Blog

Blog.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulBlogPost {
      edges {
        node {
          title
          subtitle
          author
          postDate
          image {
            fluid(maxWidth: 1500) {
              src
            }
          }
          keywords
          topicCluster
          slug
          content {
            content
          }
          authorImage {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
